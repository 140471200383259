import { useEffect } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import "./index.css";
import { SnackbarProvider } from 'notistack'
import { loadState, saveState } from "./reducers/local-storage";
// import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { ApolloProvider } from "@apollo/client";
import { Typography } from "@mui/material";
import { HttpLink } from "apollo-link-http";
import Helmet from "react-helmet";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { PersistGate } from "redux-persist/integration/react";
import { setupApolloStrytenClient } from "./apolloSetup";
import DynamicAppContainer from "./components/dynamic-app-container";
import { SEICThemeProvider } from "./shared-components/src/providers/SEICThemeProvider";
import { persistor, store } from "./store";

(async () => {
  window.global ||= window;

  const FLEET_ANALYSIS_URL = '/fleet-analysis-service'

  const link = new HttpLink({
    uri: FLEET_ANALYSIS_URL,
  });

  store.subscribe(() => {
    const state = store.getState();
    const local = loadState();

    if (state.authenticate && state.authenticate.rememberMe) {
      saveState({
        authenticate: state.authenticate,
        theme: local ? local.theme : "dark",
      });
    }
  });

  const client = await setupApolloStrytenClient();
  window.apolloClient = client;

  // This handles setting the tab with a hint as to what environment you are in
  const Title = ({ children }) => {
    useEffect(() => {
      const url = window.location.href;
      let env = "";
      if (!url || url.includes("localhost")) {
        env = "(local)";
      } else if (url.includes("dev")) {
        env = "(DEV)";
      } else if (url.includes("staging")) {
        env = "(STAGING)";
      }
      document.title = `SEIC ${env}`;
    }, []);

    return <div>{children}</div>;
  };

  const handleThemeChanged = (theme) => {
    let state = loadState();
    if (state) {
      state.theme = theme;
    } else {
      state = { theme: theme };
    }
    saveState(state);
  };

  const USERSNAP_GLOBAL_API_KEY = "6f32d02d-0a90-495c-b5af-0bcccc27c9a2";
  const USERSNAP_API_KEY = "39a7b247-d72c-47ac-90d7-20e3fef685be";

  createRoot(document.getElementById("root")).render(
    <>
      <Helmet>

      </Helmet>
      <Title>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <RecoilRoot>
            <SnackbarProvider />
              <BrowserRouter>
                <SEICThemeProvider
                  defaultTheme={
                    "dark"
                    /* persistedState ? persistedState.theme || "dark" : "dark" */
                  }
                  onThemeChanged={handleThemeChanged}
                >
                  <Typography component="div" color="textPrimary">
                    <ApolloProvider client={client}>
                      <DynamicAppContainer />
                    </ApolloProvider>
                  </Typography>
                </SEICThemeProvider>
              </BrowserRouter>
            </RecoilRoot>
          </PersistGate>
        </Provider>
      </Title>
    </>
  );

  // serviceWorkerRegistration.register();
})();
