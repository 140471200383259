import { combineSlices } from "@reduxjs/toolkit";
import { LOGOUT_USER } from "../actions";
import navReducer from "./reducer-nav";
import alertsReducer from "./reducer-alerts";
import dimensionsReducer from "./reducer-dimensions";
import authenticateReducer from "./reducer-authenticate";
import clientsReducer from "./reducer-clients";
import updatesReducer from "./reducer-updates";
import activeClientReducer from "./reducer-active-client";
import activeProjectReducer from "./reducer-active-project";
import activeFacilityReducer from "./reducer-active-facility";
import activeContactReducer from "./reducer-active-contact";
import activeVehicleReducer from "./reducer-active-vehicle";
import activeScheduleReducer from "./reducer-active-schedule";
import activeLoggerAssignmentReducer from "./reducer-active-logger-assignment";
import activeScheduleGroupReducer from "./reducer-active-schedule-group";
import activeFleetReducer from "./reducer-active-fleet";
import addProjectReducer from "./reducer-add-project-flow";
import appStateReducer from "./reducer-app-state";
import powerLoggersReducer from "./reducer-power-loggers";
import measureWorkflowReducer from "./reducer-measure-workflow";
import operationsReducer from "./reducer-operations";
import batteriesReducer from "./reducer-batteries";
import chargersReducer from "./reducer-chargers";
import teamsReducer from "./reducer-teams";
import motivePowerReducer from "./reducer-motive_power";
import taskDataReducer from "./reducer-task-data";
import userReducer from "./reducer-users";
import modelWorkflowReducer from "./reducer-model-workflow";
import userConfigReducer from "./reducer-user-config";
import tasksReducer from "./reducer-tasks";
import fleetsReducer from "./reducer-fleets";
import alertConfigurationsReducer from "./reducer-alert-configurations";
import policyDocumentReducer from "./reducer-policy-document";
import assetsReducer from "./reducer-assets";
import deviceConfigsReducer from "./reducer-device-config";
import chargerConfigsReducer from "./reducer-charger-configs";
import versionMetasReducer from "./reducer-version-metas";
import activeResourceReducer from "./reducer-active-resource";
import activeAddressReducer from "./reducer-active-address";
import tagsReducer from "./reducer-tags";
import mostRecentReducer from "./reducer-logger-most-recent";
import gatewayConfigsReducer from "./reducer-gateway-configs";
import batteryConfigsReducer from "./reducer-battery-configs";
import newTeamsReducer from "./reducer-new-teams";
import viewsReducer from "./reducer-views";
import customerGroupsReducer from "./reducer-customer-groups";
import portalRoleDefinitionsReducer from "./reducer-portal-role-definitions";
import portalGroupDefinitionsReducer from "./reducer-portal-group-definitions";
import vanadiumBESSConfigsReducer from "./reducer-vanadium-bess-configs";
import leadAcidBESSConfigsReducer from "./reducer-leadacid-bess-configs";
import licenseReducer from "./reducer-licenses";

import { reducer as formReducer } from "redux-form";
import { projectsApi } from "../services/projects/projectsApi";
import { fleetAnalyticsApi } from "../services/fleetAnalyticsApi";
import { portalApi } from "../services/portal/portalApi";
import cacheTags from "./cache-tag-slice";
import authSlice from "./auth-slice";
import { persistReducer } from "redux-persist";
import { persistStorage } from "../persistStorage";
import hardSet from "redux-persist/es/stateReconciler/hardSet";

const authPersistConfig = {
  key: "authenticate",
  storage: persistStorage,
  stateReconciler: hardSet,
  blacklist: ["loading", "error"],
};

const appReducer = combineSlices({
  form: formReducer,
  nav: navReducer,
  alerts: alertsReducer,
  dimensions: dimensionsReducer,
  authenticate: persistReducer(authPersistConfig, authenticateReducer),
  clients: clientsReducer,
  activeClient: activeClientReducer,
  activeProject: activeProjectReducer,
  activeFacility: activeFacilityReducer,
  activeContact: activeContactReducer,
  activeVehicle: activeVehicleReducer,
  activeSchedule: activeScheduleReducer,
  activeFleet: activeFleetReducer,
  activeLoggerAssignment: activeLoggerAssignmentReducer,
  activeScheduleGroup: activeScheduleGroupReducer,
  activeResource: activeResourceReducer,
  activeAddress: activeAddressReducer,
  addProjectFlow: addProjectReducer,
  updates: updatesReducer,
  appState: appStateReducer,
  powerLoggers: powerLoggersReducer,
  measureWorkflow: measureWorkflowReducer,
  operations: operationsReducer,
  batteries: batteriesReducer,
  chargers: chargersReducer,
  motivePower: motivePowerReducer,
  teams: teamsReducer,
  taskData: taskDataReducer,
  users: userReducer,
  modelWorkflow: modelWorkflowReducer,
  userConfig: userConfigReducer,
  tasks: tasksReducer,
  fleets: fleetsReducer,
  alertConfigurations: alertConfigurationsReducer,
  policyDocument: policyDocumentReducer,
  assets: assetsReducer,
  deviceConfigs: deviceConfigsReducer,
  chargerConfigs: chargerConfigsReducer,
  versionMetas: versionMetasReducer,
  tags: tagsReducer,
  loggersMostRecent: mostRecentReducer,
  gatewayConfigs: gatewayConfigsReducer,
  batteryConfigs: batteryConfigsReducer,
  newTeams: newTeamsReducer,
  views: viewsReducer,
  customerGroups: customerGroupsReducer,
  portalRoleDefinitions: portalRoleDefinitionsReducer,
  portalGroupDefinitions: portalGroupDefinitionsReducer,
  vanadiumBESSConfigs: vanadiumBESSConfigsReducer,
  leadAcidBESSConfigs: leadAcidBESSConfigsReducer,
  licenses: licenseReducer,
  cacheTags,
  auth: authSlice,
  [projectsApi.reducerPath]: projectsApi.reducer,
  [portalApi.reducerPath]: portalApi.reducer,
  [fleetAnalyticsApi.reducerPath]: fleetAnalyticsApi.reducer,
});

const rootReducer = (state = {}, action: any) => {
  if (action.type === LOGOUT_USER) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
