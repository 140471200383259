import React, { Suspense } from "react";
import { PROJECTS_RECEIVED_GET_USER_CONFIG } from "../actions/project-actions";
import LocalizedStrings from "react-localization";
import LoadingComponent from "../shared-components/src/ts-components/DynamicAppV3/LoadingComponent";
import { copyObject } from "../utility";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CustomersIcon from "@mui/icons-material/Work";
import DealersIcon from "../shared-components/src/ts-components/icons/Handshake";
import ProjectsIcon from "@mui/icons-material/BarChart";
import TrainingIcon from "@mui/icons-material/School";
import AddProjectIcon from "@mui/icons-material/AddCircle";
import AdminIcon from "@mui/icons-material/Settings";
import MeasureModelIcon from "@mui/icons-material/BarChart";
import MonitorIcon from "@mui/icons-material/Favorite";
import AssetsIcon from "../shared-components/src/ts-components/icons/AssetsIconV2";
import { LinearProgress } from "@mui/material";

const LoadingConfig = React.lazy(() =>
  import("../components/loading-config-container")
);
const Login = React.lazy(() => import("../pages/SignInPage/SignInPage"));
const Customers = React.lazy(() => import("../components/customers-container"));
const Dealers = React.lazy(() => import("../components/dealers-container"));
const Dashboard = React.lazy(() => import("../components/dashboard-container"));
const Admin = React.lazy(() => import("../components/admin-container"));
const AssetManager = React.lazy(() =>
  import("../components/asset-manager-container")
);
const UserProfile = React.lazy(() =>
  import("../components/profile-settings-container")
);
const MeasureModel = React.lazy(() =>
  import("../components/measure-model-container")
);
const Monitor = React.lazy(() => import("../components/monitor-container"));
const ThirdPartyDashboard = React.lazy(() =>
  import("../components/third-party-dashboard-container")
);
const ThirdPartyAdmin = React.lazy(() =>
  import("../components/third-party-admin-container")
);
const ThirdPartyUserProfile = React.lazy(() =>
  import("../components/third-party-profile-container")
);
const ThirdPartyCustomers = React.lazy(() =>
  import("../components/third-party-customers-container")
);
const Projects = React.lazy(() => import("../components/projects-container"));

const iconList = {
  DashboardIcon: <DashboardIcon />,
  CustomersIcon: <CustomersIcon />,
  DealersIcon: <DealersIcon />,
  ProjectsIcon: <ProjectsIcon />,
  TrainingIcon: <TrainingIcon />,
  AddProjectIcon: <AddProjectIcon />,
  AdminIcon: <AdminIcon />,
  AssetManagerIcon: <AssetsIcon height={22} width={22} />,
  MeasureModelIcon: <MeasureModelIcon />,
  MonitorIcon: <MonitorIcon />,
};

const availableRoutes = {
  loadingConfig: <LoadingConfig />,
  login: <Login />,
  customers: <Customers />,
  dealers: <Dealers />,
  dashboard: <Dashboard />,
  admin: <Admin />,
  assetManager: <AssetManager />,
  userProfile: <UserProfile />,
  measureModel: <MeasureModel />,
  monitor: <Monitor />,
  thirdPartyDashboard: <ThirdPartyDashboard />,
  thirdPartyAdmin: <ThirdPartyAdmin />,
  thirdPartyUserProfile: <ThirdPartyUserProfile />,
  thirdPartyCustomers: <ThirdPartyCustomers />,
  projects: <Projects />,
};

const strings = new LocalizedStrings({ en: {} });
const initialState = {
  stringTable: {
    en: {
      mainTitle: "GNB Cloud",
    },
  },
  appConfig: {
    mainTitleName: "mainTitle",

    menu: [],

    routes: [
      {
        route: "/login",
        index: "login",
        componentName: "login",
        params: [],
      },
      {
        route: "/reset-password",
        index: "reset-password",
        componentName: "login",
        params: [],
      },
      {
        route: "/",
        index: "",
        componentName: "loadingConfig",
        params: [],
      },
    ],

    fallbackRoute: { route: "/", index: "" },
  },
};
export default function(state = initialState, action) {
  switch (action.type) {
    case PROJECTS_RECEIVED_GET_USER_CONFIG:
      return processConfig(copyObject(action.payload));

    default:
      return processConfig(copyObject(state));
  }
}

const processConfig = (state) => {
  strings.setContent(state.stringTable);

  let appConfig = state.appConfig;

  appConfig.mainTitle = strings[appConfig.mainTitleName];

  appConfig.menu.forEach((item) => {
    item.label = strings[item.labelName];
    item.icon = iconList[item.iconName];
  });

  appConfig.routes.forEach((route) => {
    route.component = (
      <Suspense
        fallback={
          <div style={{ transform: "translateY(4px)" }}>
            <LinearProgress
              sx={{
                width: "100vw",
                height: 2,
                display: "block",
              }}
            />
          </div>
        }
      >
        {availableRoutes[route.componentName]}
      </Suspense>
    );
  });

  return state;
};
