import { ThemeOptions } from '@mui/material';
import React from 'react';

/*
    h1: true,
    h2: true,
    h3: true,
    body: true,
    bodyBold: true,
    bodySmall: true,
    bodySmallBold: true,
    caption: true,
    smallCaps: true,
    mediumCaps: true,
    largeCaps: true,
    numbersXL: true,
    numbersLarge: true,
    numbersMedium: true,
    numbersSmall: true,
    subtitle1: true,
    subtitle2: true,
    button: true,
 */
declare module '@mui/material/styles' {
  // eslint-disable-next-line no-unused-vars
  interface TypographyVariants {
    h1: React.CSSProperties;
    h2: React.CSSProperties;
    h3: React.CSSProperties;
    body: React.CSSProperties;
    bodyBold: React.CSSProperties;
    bodySmall: React.CSSProperties;
    bodySmallBold: React.CSSProperties;
    caption: React.CSSProperties;
    smallCaps: React.CSSProperties;
    mediumCaps: React.CSSProperties;
    largeCaps: React.CSSProperties;
    numbersXL: React.CSSProperties;
    numbersLarge: React.CSSProperties;
    numbersMedium: React.CSSProperties;
    numbersSmall: React.CSSProperties;
    subtitle1: React.CSSProperties;
    subtitle2: React.CSSProperties;
    button: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  // eslint-disable-next-line no-unused-vars
  interface TypographyVariantsOptions {
    h1?: React.CSSProperties;
    h2?: React.CSSProperties;
    h3?: React.CSSProperties;
    body?: React.CSSProperties;
    bodyBold?: React.CSSProperties;
    bodySmall?: React.CSSProperties;
    bodySmallBold?: React.CSSProperties;
    caption?: React.CSSProperties;
    smallCaps?: React.CSSProperties;
    mediumCaps?: React.CSSProperties;
    largeCaps?: React.CSSProperties;
    numbersXL?: React.CSSProperties;
    numbersLarge?: React.CSSProperties;
    numbersMedium?: React.CSSProperties;
    numbersSmall?: React.CSSProperties;
    subtitle1?: React.CSSProperties;
    subtitle2?: React.CSSProperties;
    button?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  // eslint-disable-next-line no-unused-vars
  interface TypographyPropsVariantOverrides {
    h1: true;
    h2: true;
    h3: true;
    body: true;
    bodyBold: true;
    bodySmall: true;
    bodySmallBold: true;
    caption: true;
    smallCaps: true;
    mediumCaps: true;
    largeCaps: true;
    numbersXL: true;
    numbersLarge: true;
    numbersMedium: true;
    numbersSmall: true;
    subtitle1: true;
    subtitle2: true;
    button: true;
  }
}

export const gnbColorPalette = {
  dark: {
    primaryLight: '#5E5E66',
    primaryMedium: '#2D2D34',
    primaryDark: '#212126',
    secondaryLight: '#7FCBD3',
    secondaryMedium: '#0097A7',
    secondaryDark: '#005A64',
    surfaceLight: '#5E5E66',
    surfaceMedium: '#3b3b43',
    surfaceDark: '#323239',
    background: '#3B3B43',
    backgroundGradient: 'linear-gradient(-225deg, #212126 0%, #2D2D34 59%)',
    textLight: '#FFFFFF',
    textMedium: '#AFAFB2',
    textDark: '#87878C',
    chart1Light: '#FCCE54',
    chart1Dark: '#FFAC12',
    chart2Light: '#FF7F57',
    chart2Dark: '#FF521C',
    chart3Light: '#72B7E8',
    chart3Dark: '#0044A7',
    chart4Light: '#8468A9',
    chart4Dark: '#663897',
    messageSuccess: '#1EB980',
    messageError: '#E24233',
    chartYellow: '#fcd879',
    chartRed: '#e24233',
    chartGrey: '#747781',
    chartBackgroundBlack: '#34353d',
    chartGreyText: '#afafb2',
    purple: '#be98e6',
    orange: '#ff8d57',
    blue: '#72b7e8',
    red: '#e24233',
    darkYellow: '#ffac12',
    lightGreen: '#82c77f',
    green: '#1eb980',
    yellow: '#fcd879',
    darkRed: '#b50000',
    darkGreen: '#007d51',
    darkBlue: '#0044a7',
    darkOrange: '#ff651c',
    darkPurple: '#663897',
    white: '#ffffff',
    lightGrey: '#afafb2',
    mediumGrey: '#87878c',
    dimGrey: '#707173',
    teal: '#34353d',
    level2: '#222328',
    level3: '#2b2c33',
    level4: '#34353d',
    level5: '#76777a',
    level6: '#a7a7a9',
    level7: '#747781',
    level8: '#a6a8ae',
    primary50: '#E5FFFF',
    primary100: '#B3FFFF',
    primary200: '#7FFFFF',
    primary300: '#4DE4F4',
    primary400: '#1AB1C1',
    primary500: '#0097A7',
    primary600: '#007E8E',
    primary700: '#006474',
    primary800: '#004B5B',
    surface: '#222328',
    reportBackground: '#f7f7f7',
    seaFoam: '#01afaa',
    black: '#000000',
  },

  light: {
    primaryLight: '#7986cb',
    primaryMedium: '#3f51b5',
    primaryDark: '#303f9f',
    secondaryLight: '#ff4081',
    secondaryMedium: '#fff',
    secondaryDark: '#c51162',
    surfaceLight: '#c51162',
    surfaceMedium: '#3b3b43',
    surfaceDark: '#323239',
    background: '#fff',
    backgroundGradient: 'linear-gradient(-225deg, #212126 0%, #2D2D34 59%)',
    textLight: '#000',
    textMedium: '#000',
    textDark: '#000',
    chart1Light: '#FCCE54',
    chart1Dark: '#FFAC12',
    chart2Light: '#FF7F57',
    chart2Dark: '#FF521C',
    chart3Light: '#72B7E8',
    chart3Dark: '#0044A7',
    chart4Light: '#8468A9',
    chart4Dark: '#663897',
    messageSuccess: '#1EB980',
    messageError: '#E24233',
    chartYellow: '#fcd879',
    chartRed: '#e24233',
    chartGrey: '#747781',
    chartBackgroundBlack: '#34353d',
    chartGreyText: '#afafb2',
    purple: '#be98e6',
    orange: '#ff8d57',
    blue: '#72b7e8',
    red: '#e24233',
    darkYellow: '#ffac12',
    lightGreen: '#82c77f',
    green: '#1eb980',
    yellow: '#fcd879',
    darkRed: '#b50000',
    darkGreen: '#007d51',
    darkBlue: '#0044a7',
    darkOrange: '#ff651c',
    darkPurple: '#663897',
    white: '#ffffff',
    lightGrey: '#afafb2',
    mediumGrey: '#87878c',
    dimGrey: '#707173',
    teal: '#34353d',
    level2: '#222328',
    level3: '#2b2c33',
    level4: '#34353d',
    level5: '#76777a',
    level6: '#a7a7a9',
    level7: '#747781',
    level8: '#a6a8ae',
    primary50: '#E5FFFF',
    primary100: '#B3FFFF',
    primary200: '#7FFFFF',
    primary300: '#4DE4F4',
    primary400: '#1AB1C1',
    primary500: '#0097A7',
    primary600: '#007E8E',
    primary700: '#006474',
    primary800: '#004B5B',
    surface: '#222328',
    reportBackground: '#f7f7f7',
    seaFoam: '#01afaa',
    black: '#000000',
  },
};

export const seicLight = {
  background: '#F0F1F4',
  onBackground: '#FFFFFF',
  surface1: '#FFFFFF',
  onSurface1: '#F0F1F4',
  surface2: '#E0E3E9',
  onSurface2: '#F0F1F4',
  surface3: '#CFD4DB',
  onSurface3: '#F0F1F4',
  inputSurface: '#0000000C',
  menuSurface: '#FFFFFF',
  outline: '#CFD4DB',
  outlineAlt: '#F0F1F4',
  textHighEmphasis: '#1B2328',
  textMediumEmphasis: '#4C555B',
  textLowEmphasis: '#7C878E',
  disabled: '#B4BAC0',

  primary: '#002D72',
  primary600: '#00245B',
  primary500: '#002D72',
  primary400: '#33578E',
  primary300: '#6681AA',
  primary200: '#99ABC7',
  primary100: '#CCD5E3',
  onPrimary: '#DBE2EB',
  primaryHover: '#002d7226',

  secondary: '#00B2A9',
  secondary600: '#004744',
  secondary500: '#006B65',
  secondary400: '#008E87',
  secondary300: '#00B2A9',
  secondary200: '#80D9D4',
  secondary100: '#B3E8E5',
  onSecondary: '#E1F6F5',

  critical: '#E64B39',
  atRisk: '#FFAB00',
  good: '#46A669',
  noData: '#7C878E',

  chart1: '#0074DE',
  chart2: '#5E63D2',
  chart3: '#5D36B4',
  chart4: '#A126A0',
  chart5: '#D61C71',
  chart6: '#ED0020',
  chart7: '#EF4E00',
  chart8: '#FBA841',
  chart9: '#979F34',
  chart10: '#006753',

  pdfBackground: '#FFFFFF',
  pdfSurface1: '#F8F8FA',
  pdfSurface2: '#F0F1F4',
  pdfOutline: '#E0E3E9',
  pdfTextHighEmphasis: '#1B2328',
  pdfTextMediumEmphasis: '#4B555B',
  pdfTextOnColor: '#FFFFFF',
  pdfPrimary: '#00B2A9',
  pdfPrimaryDark: '#006865',
  pdfPrimaryLight: '#B3E8E5',
  pdfSecondary: '#002D72',
  pdfSecondaryDark: '#00245B',
  pdfSecondaryLight: '#CCD5E3',
  pdfCritical: '#E64B39',
  pdfAtRisk: '#FFAB00',
  pdfGood: '#46A669',
  pdfNoData: '#7C878E',
  pdfChartPurple: '#5E63D2',
  pdfChartPink: '#D61C71',
  pdfChartOrange: '#EF4E00',
  pdfChartBlue: '#0074DE',

  level6: '#595C66',
  level8: '#A6A8AE',
};

export const seicDark = {
  background: '#1B2328',
  onBackground: '#242C33',
  surface1: '#242C33',
  onSurface1: '#2C363E',
  surface2: '#2C363E',
  onSurface2: '#333F48',
  surface3: '#333F48',
  onSurface3: '#434E57',
  inputSurface: '#FFFFFF0C',
  menuSurface: '#2C363E',
  outline: '#333F48',
  outlineAlt: '#1B2328',
  textHighEmphasis: '#FFFFFF',
  textMediumEmphasis: '#BEC3C7',
  textLowEmphasis: '#7C878E',
  disabled: '#4C555B',

  primary: '#00B2A9',
  primary600: '#004744',
  primary500: '#006B65',
  primary400: '#008E87',
  primary300: '#00B2A9',
  primary200: '#80D9D4',
  primary100: '#B3E8E5',
  onPrimary: '#003230',
  primaryHover: '#00b2a926',

  secondary: '#0088FF',
  secondary600: '#1943B9',
  secondary500: '#1463D8',
  secondary400: '#0088FF',
  secondary300: '#2FA6FF',
  secondary200: '#8DCBFF',
  secondary100: '#BADFFF',
  onSecondary: '#0A1B4A',

  critical: '#E64B39',
  atRisk: '#FFC95C',
  good: '#46A669',
  noData: '#7C878E',

  chart1: '#3AB3FF',
  chart2: '#7AA9FF',
  chart3: '#9E7DD7',
  chart4: '#D772C7',
  chart5: '#FF68A6',
  chart6: '#FF5C69',
  chart7: '#FF8549',
  chart8: '#FFBF47',
  chart9: '#CBE364',
  chart10: '#72C0B3',

  pdfBackground: '#FFFFFF',
  pdfSurface1: '#F8F8FA',
  pdfSurface2: '#F0F1F4',
  pdfOutline: '#E0E3E9',
  pdfTextHighEmphasis: '#1B2328',
  pdfTextMediumEmphasis: '#4B555B',
  pdfTextOnColor: '#FFFFFF',
  pdfPrimary: '#00B2A9',
  pdfPrimaryDark: '#006865',
  pdfPrimaryLight: '#B3E8E5',
  pdfSecondary: '#002D72',
  pdfSecondaryDark: '#00245B',
  pdfSecondaryLight: '#CCD5E3',
  pdfCritical: '#E64B39',
  pdfAtRisk: '#FFAB00',
  pdfGood: '#46A669',
  pdfNoData: '#7C878E',
  pdfChartPurple: '#5E63D2',
  pdfChartPink: '#D61C71',
  pdfChartOrange: '#EF4E00',
  pdfChartBlue: '#0074DE',

  level6: '#595C66',
  level8: '#A6A8AE',
};

export const gnbLightTheme: ThemeOptions = {
  typography: {
    useNextVariants: true,
    fontFamily: 'Roboto, sans-serif',
    h1: {
      fontWeight: 400,
      fontSize: 32,
      fontStyle: 'normal',
    },
    h2: {
      fontWeight: 600,
      fontSize: 24,
      fontStyle: 'normal',
    },
    h3: {
      fontWeight: 600,
      fontSize: 20,
      fontStyle: 'normal',
      letterSpacing: 0.13,
    },
    body: {
      fontWeight: 400,
      fontSize: 16,
      fontStyle: 'normal',
    },
    bodyBold: {
      fontWeight: 600,
      fontSize: 16,
      fontStyle: 'normal',
    },
    bodySmall: {
      fontWeight: 400,
      fontSize: 14,
      fontStyle: 'normal',
      letterSpacing: 0.125,
    },
    bodySmallBold: {
      fontWeight: 600,
      fontSize: 14,
      fontStyle: 'normal',
      letterSpacing: 0.125,
    },
    caption: {
      fontWeight: 400,
      fontSize: 12,
      fontStyle: 'normal',
      letterSpacing: 0.125,
    },
    smallCaps: {
      fontWeight: 600,
      fontSize: 10,
      fontStyle: 'normal',
      letterSpacing: 0.5,
      textTransform: 'uppercase',
    },
    mediumCaps: {
      fontWeight: 600,
      fontSize: 12,
      fontStyle: 'normal',
      letterSpacing: 1,
      textTransform: 'uppercase',
    },
    largeCaps: {
      fontWeight: 600,
      fontSize: 14,
      fontStyle: 'normal',
      letterSpacing: 1,
      textTransform: 'uppercase',
    },
    numbersXL: {
      fontWeight: 600,
      fontSize: 42,
      fontStyle: 'normal',
      letterSpacing: 0.125,
    },
    numbersLarge: {
      fontWeight: 600,
      fontSize: 32,
      fontStyle: 'normal',
      letterSpacing: 0.125,
    },
    numbersMedium: {
      fontWeight: 600,
      fontSize: 24,
      fontStyle: 'normal',
      letterSpacing: 0.125,
    },
    numbersSmall: {
      fontWeight: 600,
      fontSize: 16,
      fontStyle: 'normal',
      letterSpacing: 0.125,
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: 16,
      letterSpacing: 0.15,
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: 14,
      letterSpacing: 0.1,
    },
    button: {
      fontWeight: 500,
      fontSize: 14,
      letterSpacing: 1.0,
    },
  },
  palette: {
    mode: 'light',
    common: {
      black: '#000',
      white: '#fff',
    },
    background: {
      paper: seicLight.background,
      default: seicLight.background,
    },
    primary: {
      light: seicLight.primary400,
      main: seicLight.primary,
      dark: seicLight.primary,
      contrastText: seicLight.onPrimary,
    },
    secondary: {
      main: seicLight.secondary,
    },
    error: {
      main: seicLight.critical,
    },
    text: {
      default: seicLight.textHighEmphasis,
      secondary: seicLight.textMediumEmphasis,
      primary: seicLight.textMediumEmphasis,
      disabled: seicLight.textLowEmphasis,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        ButtonBase: {
          disabled: {
            backgroundColor: seicLight.disabled,
            color: seicLight.textHighEmphasis,
          },
        },
        containedPrimary: {
          backgroundColor: seicLight.primary,
          color: seicLight.onPrimary,
        },
        outlined: {
          borderColor: seicLight.primary,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        outlinedSuccess: {
          border: `1px solid ${seicLight.primary}`,
          color: seicLight.textHighEmphasis,
          '& .MuiAlert-icon': {
            color: seicLight.primary,
          },
          '& .MuiAlert-action': {
            color: seicLight.textMediumEmphasis,
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          color: seicLight.textHighEmphasis,
          backgroundColor: seicLight.surface1,
          backgroundImage: 'none',
        },
      },
    },
    MuiIcon: {
      styleOverrides: {
        root: {
          color: seicLight.textHighEmphasis,
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          backgroundColor: seicLight.surface1,
          '&.Mui-selected': {
            backgroundColor: seicLight.primary,
            color: seicLight.onPrimary,
            '&.Mui-selected:hover': {
              backgroundColor: seicLight.primary200,
              color: seicLight.onPrimary,
            },
          },
        },
      },
    },
  },
  overrides: {
    MuiDrawer: {
      paper: {
        background: seicLight.surface1,
      },
    },
    MuiIcon: {
      colorPrimary: seicLight.textMediumEmphasis,
      colorSecondary: seicLight.textMediumEmphasis,
    },
    MuiSvgIcon: {
      colorPrimary: {
        color: seicLight.textMediumEmphasis,
      },
      colorSecondary: {
        color: seicLight.textMediumEmphasis,
      },
    },
    MuiTableCell: {
      body: {
        fontSize: 16,
      },
      head: {
        fontSize: 16,
        color: seicLight.textMediumEmphasis,
      },
    },
    MuiSelect: {
      // see https://github.com/mui-org/material-ui/issues/9826
      select: {
        padding: undefined,
        //paddingRight: theme.spacing(4),
        height: undefined,
      },
      selectMenu: {
        lineHeight: undefined,
      },
    },
    appVar: {
      backgroundColor: seicLight.surface1,
    },
  },
};

export const gnbDarkTheme: ThemeOptions = {
  typography: {
    useNextVariants: true,
    fontFamily: 'Roboto, sans-serif',
    h1: {
      fontWeight: 400,
      fontSize: 32,
      fontStyle: 'normal',
    },
    h2: {
      fontWeight: 600,
      fontSize: 24,
      fontStyle: 'normal',
    },
    h3: {
      fontWeight: 600,
      fontSize: 20,
      fontStyle: 'normal',
      letterSpacing: 0.13,
    },
    body: {
      fontWeight: 400,
      fontSize: 16,
      fontStyle: 'normal',
    },
    bodyBold: {
      fontWeight: 600,
      fontSize: 16,
      fontStyle: 'normal',
    },
    bodySmall: {
      fontWeight: 400,
      fontSize: 14,
      fontStyle: 'normal',
      letterSpacing: 0.125,
    },
    bodySmallBold: {
      fontWeight: 600,
      fontSize: 14,
      fontStyle: 'normal',
      letterSpacing: 0.125,
    },
    caption: {
      fontWeight: 400,
      fontSize: 12,
      fontStyle: 'normal',
      letterSpacing: 0.125,
    },
    smallCaps: {
      fontWeight: 600,
      fontSize: 10,
      fontStyle: 'normal',
      letterSpacing: 0.5,
      textTransform: 'uppercase',
    },
    mediumCaps: {
      fontWeight: 600,
      fontSize: 12,
      fontStyle: 'normal',
      letterSpacing: 1,
      textTransform: 'uppercase',
    },
    largeCaps: {
      fontWeight: 600,
      fontSize: 14,
      fontStyle: 'normal',
      letterSpacing: 1,
      textTransform: 'uppercase',
    },
    numbersXL: {
      fontWeight: 600,
      fontSize: 42,
      fontStyle: 'normal',
      letterSpacing: 0.125,
    },
    numbersLarge: {
      fontWeight: 600,
      fontSize: 32,
      fontStyle: 'normal',
      letterSpacing: 0.125,
    },
    numbersMedium: {
      fontWeight: 600,
      fontSize: 24,
      fontStyle: 'normal',
      letterSpacing: 0.125,
    },
    numbersSmall: {
      fontWeight: 600,
      fontSize: 16,
      fontStyle: 'normal',
      letterSpacing: 0.125,
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: 16,
      letterSpacing: 0.15,
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: 14,
      letterSpacing: 0.1,
    },
    button: {
      fontWeight: 500,
      fontSize: 14,
      letterSpacing: 1.0,
    },
  },
  palette: {
    mode: 'dark',
    common: {
      black: '#000',
      white: '#fff',
    },
    background: {
      paper: seicDark.background,
      default: seicDark.background,
    },
    primary: {
      main: seicDark.primary,
      contrastText: seicDark.onPrimary,
    },
    secondary: {
      main: seicDark.secondary,
    },
    error: {
      main: seicDark.critical,
    },
    text: {
      default: seicDark.textHighEmphasis,
      secondary: seicDark.textMediumEmphasis,
      primary: seicDark.textHighEmphasis,
      disabled: seicDark.textLowEmphasis,
    },
    action: {
      disabledBackground: seicDark.onSurface1,
      disabled: seicDark.disabled,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        ButtonBase: {
          disabled: {
            backgroundColor: seicDark.disabled,
            color: seicDark.textHighEmphasis,
          },
        },
        containedPrimary: {
          backgroundColor: seicDark.primary,
          color: seicDark.onPrimary,
        },
        outlined: {
          borderColor: seicDark.primary,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        outlinedSuccess: {
          border: `1px solid ${seicDark.primary}`,
          color: seicDark.textHighEmphasis,
          '& .MuiAlert-icon': {
            color: seicDark.primary,
          },
          '& .MuiAlert-action': {
            color: seicDark.textMediumEmphasis,
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          color: seicDark.textHighEmphasis,
          backgroundColor: seicDark.onSurface1,
          backgroundImage: 'none',
        },
      },
    },
    MuiIcon: {
      styleOverrides: {
        root: {
          color: seicDark.textHighEmphasis,
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          backgroundColor: seicDark.surface1,

          '&.Mui-selected': {
            backgroundColor: seicDark.primary,
            color: seicDark.onPrimary,
          },
        },
      },
    },
  },
  overrides: {
    MuiDrawer: {
      paper: {
        background: seicDark.background,
      },
    },
    MuiIcon: {
      colorPrimary: seicDark.textMediumEmphasis,
      colorSecondary: seicDark.textMediumEmphasis,
    },
    MuiSvgIcon: {
      colorPrimary: {
        color: seicDark.textMediumEmphasis,
      },
      colorSecondary: {
        color: seicDark.textMediumEmphasis,
      },
    },
    MuiTableCell: {
      body: {
        fontSize: 16,
      },
      head: {
        fontSize: 16,
        color: seicDark.textMediumEmphasis,
      },
    },
    MuiButton: {
      contained: {
        color: seicDark.onPrimary,
        backgroundColor: seicDark.primary,
      },
      textSecondary: {
        color: seicDark.textLowEmphasis,
      },
      containedSecondary: {
        color: seicDark.onSurface2,
        backgroundColor: seicDark.surface2,
      },
      outlined: {
        border: `1px solid ${seicDark.secondary}`,
      },
    },
    MuiSelect: {
      // see https://github.com/mui-org/material-ui/issues/9826
      select: {
        padding: undefined,
        //paddingRight: theme.spacing(4),
        height: undefined,
      },
      selectMenu: {
        lineHeight: undefined,
      },
    },
    appVar: {
      backgroundColor: seicDark.surface1,
    },
  },
};
