import { createApi } from "@reduxjs/toolkit/query/react";
import { gql } from "graphql-request";
import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";
import { basicHeaders } from "./utils";

type Alert = {
  job_id: string;
  alert_setting_id: string;
  alert_level: string;
  date: string;
  count_of_days: number;
  power_logger_name: string;
  fleet_id: string;
  client_id: string;
  project_id: string;
  project_workflow_id: string;
};
type GetLatestAlertsResponse = {
  latestAlerts: Alert[];
};
export const fleetAnalyticsApi = createApi({
  reducerPath: "fleetAnalyticsApi",
  baseQuery: graphqlRequestBaseQuery({
    url: `${import.meta.env.VITE_APP_FLEET_ANALYSIS_SERVICE}/graphql`,
    prepareHeaders: basicHeaders,
  }),
  endpoints: (builder) => ({
    getLatestAlerts: builder.query<GetLatestAlertsResponse, void>({
      query: () => ({
        document: gql`
          query {
            latestAlerts {
              job_id
              alert_setting_id
              alert_level
              date
              count_of_days
              power_logger_name
              fleet_id
              client_id
              project_id
              project_workflow_id
            }
          }
        `,
      }),
    }),

    gatewayStatus: builder.query<GetLatestAlertsResponse, void>({
      query: () => ({
        document: gql`
          query getGatewayStatus($gatewaySerial: String) {
            gatewayStatus(gatewaySerial: $gatewaySerial) {
              gatewaySerial
              createdAt
              updatedAt
              status {
                eir_count
                status
                humidity
                errors
                user_seq
                warnings
                internal_temp_f
              }
            }
          }
        `,
      }),
    }),

    gatewayStatusList: builder.query<GetLatestAlertsResponse, void>({
      query: (ids) => ({
        document: gql`
          query getGatewayStatusList($gatewaySerials: [String!]) {
            gatewayStatusList(gatewaySerials: $gatewaySerials) {
              gatewaySerial
              createdAt
              updatedAt
              status {
                eir_count
                status
                humidity
                errors
                user_seq
                warnings
                internal_temp_f
              }
            }
          }
        `,
        variables: {
          gatewaySerials: ids,
        },
      }),
    }),
  }),
});

export const {
  useGetLatestAlertsQuery,
  useGatewayStatusListQuery,
} = fleetAnalyticsApi;
